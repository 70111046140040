<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else class="mr-4 md:mr-0">
    <trac-back-button>Users &amp; Roles</trac-back-button>
    <div class="flex items-center justify-between my-6 px-2">
      <div class="mt-4">
        <h2 class="text-xs">Settings</h2>
      <h1 class="font-medium mt-2">Users</h1>
      </div>
    </div>
    <div class="mb-8 mt-12 border rounded-lg overflow-hidden p-6">
      <div class="pb-4 flex flex-col md:items-end md:justify-between">
        <div class="relative max-w-sm rounded-md w-full flex items-center mb-4 md:mb-0">
          <input
            type="text"
            placeholder="Search User"
            class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
            v-model="search"
          />
          <svg
            class="w-4 h-4 absolute right-0 mr-6"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6.56705"
              cy="6.61686"
              r="5.39909"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.3156 13.3659L10.3799 10.4302"
              stroke="#253B95"
              stroke-width="1.06786"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <trac-button
          @click.native="$router.push({ name: 'EmployeeDetailsUser' })"
        >
          <div class="flex items-center justify-center">
            <svg
              class="w-4 h-4 mr-3"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9.33333"
                cy="9.33333"
                r="8.33333"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33366 6V12.6667"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 9.33366H12.6667"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Add New User
          </div>
        </trac-button>
      </div>
      <div class="overflow-scroll">
      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr class="h-16 rounded-tl-sm">
            <th
              class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark"
            >
              Name
            </th>
            <th
              class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark"
            >
              Role
            </th>
            <th
              class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark"
            >
              Locations
            </th>
            <th
              class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody v-if="filteredUser.length > 0">
          <tr
            class="cursor-pointer even:bg-transparentBackground"
            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
            v-for="(user, index) in filteredUser"
            :key="index"
            @click="sendtoEmployee(user)"
          >
            <td class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-medium">
              <div class="flex items-center capitalize">
                <span>{{ user.first_name + " " + user.last_name }}</span>
              </div>
            </td>
            <td class="capitalize text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-bold">
              {{ user.role }}
            </td>
            <td class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-medium">
              <div
                class="flex items-center capitalize"
                v-for="(store, i) in user.stores"
                :key="i"
              >
                {{ store.name || "main" }}
              </div>
            </td>
            <td class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark">
              <div class="flex flex-row">
                {{ user.active ? "Activated" : "Deactivated" }}
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
            <tr>
              <td class="text-xs font-medium p-4" colspan="6">
                <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      No user added yet.
                    </div>
                  </trac-center-data>
              </td>
            </tr>
          </tbody>
      </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      users: [],
      search: "",
      loading: false,
    };
  },
  computed: {
    filteredUser() {
      return [...this.users].filter(
        (user) =>
          user.first_name
            .toLowerCase()
            .includes(this.search.trim().toLowerCase()) ||
          user.last_name
            .toLowerCase()
            .includes(this.search.trim().toLowerCase())
      );
    },
  },
  methods: {
    sendtoEmployee(x) {
      this.$store.commit("setUserDetails", x);
      this.$router.push({ name: "EditEmployeeDetailsUser" });
    },
  },
  async created() {
    this.loading = true;
    let res = await this.$store.dispatch("FETCH_ALL_USERS");
    if (res.status) {
      this.loading = false;
      this.users = (res.data || []).sort((a,b) => (a.first_name + " " + a.last_name).trim().toLowerCase() > (b.first_name + " " + b.last_name).trim().toLowerCase() ? 1 : -1);
    }
  },
};
</script>

<style></style>>
